import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, IconButton, Stack, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { RefreshButton } from '@r40cap/ui'

import AlertsTypeInput from './AlertsTypeInput'
import { AlertsType } from './types'

const REDIRECT_TYPE = AlertsType.MARGIN

function AlertsControlsPanel (props: {
  hasEdited: boolean
  pushEditsFunction: () => void
  refreshFunction: () => void
}): React.JSX.Element {
  const {
    hasEdited,
    pushEditsFunction,
    refreshFunction
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const alertsType = (location.pathname.split('/')[2] ?? REDIRECT_TYPE) as AlertsType

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '20%',
          minWidth: '150px'
        }}
      >
        <AlertsTypeInput
          currentType={alertsType}
          typeChangeFunction={(newType) => { navigate(`/alerts/${newType}`) }}
        />
      </Box>
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        sx={{ height: '50%' }}
      >
        {
          hasEdited && <Button
            variant='outlined'
            onClick={pushEditsFunction}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main
            }}
          >
            PUSH
          </Button>
        }
        <IconButton
          onClick={() => { navigate(`/alerts/${alertsType}/new`) }}
        >
          <AddIcon />
        </IconButton>
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
  )
}

export default AlertsControlsPanel
