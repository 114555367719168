export interface AlertRow {
  id: string
  account: string
  alertType: string
  alertSide: string
  alertSideSign: number
  alertDeliveryMethod: string
  deliveryIdentifier?: string
  user: string
  userId: string
  currencyTicker?: string
  movement?: number
  healthThreshold: number
  cooldownMinutes: number
}

export enum InputType {
  STRING,
  FLOAT,
  POS_FLOAT,
  POS_INTEGER,
  ACCOUNT,
  ALERT_TYPE,
  ALERT_SIDE,
  DELIVERY_METHOD
}
