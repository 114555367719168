import React, { useMemo } from 'react'
import {
  CircularProgress,
  Table,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { HeaderCell, ValueCell, stableSort, getComparator } from '@r40cap/ui'
import type { PositionItem } from '@r40cap/pms-sdk'

import type { PlatformPositionRow } from '../types'
import { platformPositionsColumns } from '../constants'
import { getDecimals } from './utils'

function RpmsPositionsSubTableRow (props: {
  containedRows: PositionItem[]
  currencyPriceMap: Map<string, number>
  isFetching: boolean
  colsToSpan: number
  includeSmallAmounts: boolean
  smallThreshold: number
}): React.JSX.Element {
  const { palette } = useTheme()
  const {
    colsToSpan,
    isFetching,
    currencyPriceMap,
    containedRows,
    includeSmallAmounts,
    smallThreshold
  } = props

  function getPositionPriceUsd (price: number, priceQuoteCurrencyId: string): number {
    if (priceQuoteCurrencyId === 'USD') {
      return price
    } else {
      const quoteCurrencyPrice = currencyPriceMap.get(priceQuoteCurrencyId)
      return quoteCurrencyPrice !== undefined ? price * quoteCurrencyPrice : 0
    }
  }

  const visibleRows = useMemo(
    () => {
      const filteredForSmallRows = includeSmallAmounts
        ? containedRows
        : containedRows.filter(
          (row) => {
            const rowPriceUsd = getPositionPriceUsd(row.priceInfo.price, row.priceInfo.priceQuoteCurrencyId)
            if (rowPriceUsd === undefined) {
              return true
            } else {
              return Math.abs(row.quantity * rowPriceUsd * row.multiplier) > smallThreshold
            }
          }
        )
      return stableSort(filteredForSmallRows, (a, b) => {
        const aPriceUsd = getPositionPriceUsd(a.priceInfo.price, a.priceInfo.priceQuoteCurrencyId)
        const bPriceUsd = getPositionPriceUsd(b.priceInfo.price, b.priceInfo.priceQuoteCurrencyId)
        return getComparator('desc', 'marketValue')({
          marketValue: Math.abs(a.quantity * aPriceUsd * a.multiplier)
        },
        {
          marketValue: Math.abs(b.quantity * bPriceUsd * b.multiplier)
        })
      })
    },
    [containedRows, includeSmallAmounts, smallThreshold, currencyPriceMap]
  )

  return (
    <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
      {
        isFetching
          ? <>
            <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
              <Typography variant='h6'>Positions</Typography>
            </TableCell>
            <TableCell align='center' colSpan={colsToSpan - 1}>
              <CircularProgress style={{ color: palette.accent.main }}/>
            </TableCell>
          </>
          : visibleRows.length === 0
            ? <TableCell colSpan={colsToSpan} align='center' sx={{ alignContent: 'center' }}>
              <Typography variant='h6'>
                {
                  containedRows.length === 0
                    ? 'No Positions'
                    : `Hiding ${containedRows.length} Small ${containedRows.length === 1 ? 'Position' : 'Positions'}`
                }
              </Typography>
            </TableCell>
            : <>
              <TableCell colSpan={1} sx={{ alignContent: 'start' }}>
                <Typography variant='h6'>Positions</Typography>
              </TableCell>
              <TableCell colSpan={colsToSpan - 1}>
                <Table size='small' sx={{ borderRadius: '3px' }}>
                  <TableRow>
                    {
                      platformPositionsColumns.map((column, idx) => (
                        <HeaderCell<PlatformPositionRow, any>
                          column={column}
                          isActiveSort={false}
                          order={'asc'}
                          sortHandler={(): void => {}}
                          key={idx}
                          backgroundColor={palette.background.default}
                          textColor={palette.tableHeaderText.main}
                          activeTextColor={palette.accent.main}
                          dense
                        />
                      ))
                    }
                  </TableRow>
                  {
                    visibleRows.map((row) => (
                      <TableRow key={row.id}>
                        {
                          platformPositionsColumns.map((column, index) => {
                            if (column.isDummyColumn as boolean) {
                              return <TableCell key={index} />
                            } else {
                              const rowPriceUsd = getPositionPriceUsd(row.priceInfo.price, row.priceInfo.priceQuoteCurrencyId)
                              const [quantityDecimals, priceDecimals] = getDecimals(rowPriceUsd)
                              return <ValueCell<PlatformPositionRow, any>
                                column={column}
                                item={{
                                  instrumentName: row.ticker,
                                  quantity: row.quantity,
                                  multiplier: row.multiplier,
                                  price: rowPriceUsd,
                                  delta: row.delta,
                                  marketValue: rowPriceUsd * row.multiplier * row.quantity,
                                  quantityDecimals,
                                  priceDecimals,
                                  annualizedFundingRate: (row.annualizedFundingRate === undefined || row.annualizedFundingRate === null) ? undefined : row.annualizedFundingRate * 100
                                }}
                                key={index}
                                defaultTextColor={palette.tableBodyText.main}
                                redTextColor='red'
                                greenTextColor='green'
                              />
                            }
                          })
                        }
                      </TableRow>
                    ))
                  }
                </Table>
              </TableCell>
            </>
      }
    </TableRow>
  )
}

export default RpmsPositionsSubTableRow
