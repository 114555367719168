import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import { authApi } from '@r40cap/auth'
import { blotterApi, glossaryApi, priceApi, portfolioApi, reconciliationApi, riskApi } from '@r40cap/pms-sdk'
import { marketDataApi } from '@r40cap/market-data-sdk'
import { permissionsApi, marginAlertsApi, configApi } from '@r40cap/alerts-sdk'

import themeReducer from '../features/theme/themeSlice'

const persistConfig = {
  key: 'root',
  storage
}

const persistedThemeReducer = persistReducer(persistConfig, themeReducer)

export const store = configureStore({
  reducer: {
    theme: persistedThemeReducer,
    [authApi.reducerPath]: authApi.reducer,
    [blotterApi.reducerPath]: blotterApi.reducer,
    [glossaryApi.reducerPath]: glossaryApi.reducer,
    [portfolioApi.reducerPath]: portfolioApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [reconciliationApi.reducerPath]: reconciliationApi.reducer,
    [riskApi.reducerPath]: riskApi.reducer,
    [marketDataApi.reducerPath]: marketDataApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [marginAlertsApi.reducerPath]: marginAlertsApi.reducer,
    [configApi.reducerPath]: configApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([
      authApi.middleware,
      blotterApi.middleware,
      glossaryApi.middleware,
      portfolioApi.middleware,
      priceApi.middleware,
      reconciliationApi.middleware,
      riskApi.middleware,
      marketDataApi.middleware,
      permissionsApi.middleware,
      marginAlertsApi.middleware,
      configApi.middleware
    ]),
  devTools: true
})

export type RootState = ReturnType<typeof store.getState>
