import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import { Box, Stack } from '@mui/material'
import { usePermissions as useAlertsPermissions } from '@r40cap/alerts-sdk'

import NoAccess from './NoAccess'
import Page from './Page'
import AlertsControlsPanel from '../components/alerts/AlertsControlsPanel'
import { type AlertsType } from '../components/alerts/types'
import MarginAlerts from '../components/alerts/MarginAlerts/MarginAlerts'
import AddMarginAlertModal from '../components/alerts/MarginAlerts/AddMarginAlertModal'
import DeleteMarginAlertModal from '../components/alerts/MarginAlerts/DeleteMarginAlertModal'

const REDIRECT_TYPE = 'margin' as AlertsType

function Alerts (): React.JSX.Element {
  const alertsPermissions = useAlertsPermissions()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [pushSignal, setPushSignal] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  const props = {
    refreshSignal,
    pushSignal,
    setHasEdited
  }

  return (
    alertsPermissions.username !== undefined && alertsPermissions.username !== null
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <AlertsControlsPanel
                hasEdited={hasEdited}
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                pushEditsFunction={() => { setPushSignal(!pushSignal) }}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <Routes>
                <Route path='' element={<Navigate to={REDIRECT_TYPE} replace />} />
                <Route path={'margin'} element={<MarginAlerts {...props}/>}>
                  <Route path={'new'} element={<AddMarginAlertModal />} />
                  <Route path={'delete/:marginAlertId'} element={<DeleteMarginAlertModal />} />
                </Route>
                <Route path='*' element={<Navigate to={REDIRECT_TYPE} replace />}/>
              </Routes>
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Alerts
