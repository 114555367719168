import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Modal } from '@r40cap/ui'

import DeleteMarginAlertModalContent from './DeleteMarginAlertModalContent'

function DeleteMarginAlertModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { marginAlertId } = useParams()

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/alerts/margin')
    }
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <DeleteMarginAlertModalContent
        marginAlertId={marginAlertId ?? ''}
        closeModal={exitModal}
      />
    </Modal>
  )
}

export default DeleteMarginAlertModal
