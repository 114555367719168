import React, { useEffect, useMemo, useState } from 'react'
import { IconButton, TableCell, TableRow, useTheme } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import { type Order, ValueCell, getComparator, stableSort } from '@r40cap/ui'
import { type SubStrategyPnl } from '@r40cap/pms-sdk'

import { pnlColumns } from '../constants'
import { type PnlRow } from '../types'
import BaseFxRow from './BaseFxRow'

function SubStrategyRow (props: {
  subStrategyPnl: SubStrategyPnl
  order: Order
  orderBy: keyof PnlRow
  forcedOpen: boolean
  forcedClosed: boolean
  invalidateForces: () => void
  conciseBasis: boolean
}): React.JSX.Element {
  const {
    subStrategyPnl,
    order,
    orderBy,
    forcedOpen,
    forcedClosed,
    invalidateForces,
    conciseBasis
  } = props
  const [open, setOpen] = useState<boolean>(false)
  const { palette } = useTheme()

  const visibleBaseFxs = useMemo(
    () => stableSort(subStrategyPnl.baseFxs, (a, b) => {
      const pnlRowA: PnlRow = {
        title: a.name,
        day: a.pnlSnapshot.day,
        week: a.pnlSnapshot.week,
        month: a.pnlSnapshot.month,
        year: a.pnlSnapshot.year,
        inception: a.pnlSnapshot.inception
      }
      const pnlRowB: PnlRow = {
        title: b.name,
        day: b.pnlSnapshot.day,
        week: b.pnlSnapshot.week,
        month: b.pnlSnapshot.month,
        year: b.pnlSnapshot.year,
        inception: b.pnlSnapshot.inception
      }
      return getComparator(order, orderBy)(pnlRowA, pnlRowB)
    }),
    [order, orderBy, subStrategyPnl]
  )

  useEffect(() => {
    if (forcedOpen) {
      setOpen(true)
    }
  }, [forcedOpen])

  useEffect(() => {
    if (forcedClosed) {
      setOpen(false)
    }
  }, [forcedClosed])

  const onlyTether = conciseBasis && subStrategyPnl.name === 'Futures Basis'

  return (
    <>
      <TableRow sx={{ backgroundColor: palette.tertiary.main }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            disabled
          >
            <SubdirectoryArrowRightIcon fontSize='small'/>
          </IconButton>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!(open as boolean))
              invalidateForces()
            }}
          >
            {
              (forcedOpen || (open as boolean && !forcedClosed))
                ? <KeyboardArrowUpIcon fontSize='small'/>
                : <KeyboardArrowDownIcon fontSize='small'/>
            }
          </IconButton>
        </TableCell>
        {
          pnlColumns.map((column, idx) => (
            <ValueCell<PnlRow, any>
              column={column}
              item={{
                title: `\u00A0\u00A0\u00A0${subStrategyPnl.name}`,
                day: subStrategyPnl.pnlSnapshot.day,
                week: subStrategyPnl.pnlSnapshot.week,
                month: subStrategyPnl.pnlSnapshot.month,
                year: subStrategyPnl.pnlSnapshot.year,
                inception: subStrategyPnl.pnlSnapshot.inception
              }}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              key={idx}
            />
          ))
        }
      </TableRow>
      {(forcedOpen || (open && !forcedClosed)) && visibleBaseFxs.filter(baseFx => (!onlyTether || baseFx.name === 'USDT')).map((baseFx, idx) => <BaseFxRow
        baseFxPnl={baseFx}
        key={idx}
      />)}
    </>
  )
}

export default SubStrategyRow
