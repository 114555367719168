import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  Stack,
  Table,
  TableContainer,
  TableFooter,
  Typography,
  useTheme
} from '@mui/material'
import { Modal, type Order, TableSkeleton, stableSort, getComparator } from '@r40cap/ui'
import { type CollateralItem, type PositionItem, riskApi } from '@r40cap/pms-sdk'

import HealthScoreDisplay from '../HealthScoreDisplay'
import SortableHeader from '../SortableHeader'
import { collateralColumns, positionColumns } from './columns'
import type { CollateralRow, PositionRow } from './types'
import AccountTableBody from '../AccountTableBody'
import AccountTableFooterRow from '../AccountTableFooterRow'
import { getModalContent } from '../utils'
import type { InputType, ShockEffect } from '../types'
import { getDecimals } from '../../tables/rows/utils'
import ShockModalContent from '../ShockModalContent'

function getBybitMaintenanceMarginRequirement (
  balances: readonly CollateralItem[],
  positions: readonly PositionItem[]
): number {
  const currencyToPrice = new Map(balances.map(item => [item.id, item.price]))
  const totalMaintenanceMargin = positions.reduce((acc, psn) => {
    const valueInQuote = psn.quantity * psn.priceInfo.price
    const quotePrice = currencyToPrice.get(psn.quoteCurrencyId) ?? 0
    const valueUsd = valueInQuote * quotePrice
    return acc + Math.abs(valueUsd) * psn.marginRequirementCoefficient
  }, 0)
  return totalMaintenanceMargin
}

function getBybitDiscountedEquity (
  balances: readonly CollateralItem[],
  positions: readonly PositionItem[]
): number {
  const currencyToPrice = new Map(balances.map(item => [item.id, item.price]))
  const currencyToHaircut = new Map(balances.map(item => [item.id, item.haircut]))
  const nonPnlEquity = balances.reduce((acc, balance) => {
    return acc + balance.balance * balance.price * balance.haircut
  }, 0)
  const pnlEquity = positions.reduce((acc, psn) => {
    const pnlInSettlementCurrency = psn.settlementInfo?.unrealizedPnl ?? 0
    const price = currencyToPrice.get(psn.settlementInfo?.settlementCurrencyId ?? '') ?? 0
    const haircut = currencyToHaircut.get(psn.settlementInfo?.settlementCurrencyId ?? '') ?? 0
    return acc + pnlInSettlementCurrency * price * haircut
  }, 0)
  return nonPnlEquity + pnlEquity
}

function BybitCollateralView (props: {
  data: readonly CollateralItem[]
  isFetching: boolean
  editSubmission: (property: keyof CollateralItem, value: any, editedCurrencyId: string) => void
  discountedEquity: number | null
}): React.JSX.Element {
  const {
    data,
    isFetching,
    editSubmission,
    discountedEquity
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<CollateralRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof CollateralRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof CollateralRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    setRows(data.map((bal: CollateralItem) => {
      const [quantDec, pxDec] = getDecimals(bal.price)
      return {
        id: bal.id,
        ticker: bal.ticker,
        balance: bal.balance,
        price: bal.price,
        haircut: bal.haircut,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: bal.balance * bal.price * bal.multiplier
      }
    }))
  }, [data])

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof CollateralItem): void {
    if (property !== undefined && selectedIds.length === 1) {
      editSubmission(property, value, selectedIds[0])
    }
  }

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )

  const discountedEquityStr = (discountedEquity ?? 0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Collateral
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={collateralColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isFetching
                ? <TableSkeleton numRows={5} columns={collateralColumns}/>
                : <AccountTableBody
                  rows={visibleRows}
                  handleOpenEdit={
                    (
                      itemId: string,
                      inputType: InputType,
                      label: string,
                      editProperty: keyof CollateralItem
                    ) => {
                      setEditModalOpen(true)
                      setEditModalContent(
                        getModalContent(
                          inputType,
                          label,
                          editProperty,
                          () => { setEditModalOpen(false) },
                          handleSubmission,
                          [itemId],
                          () => {}
                        )
                      )
                    }
                  }
                  columns={collateralColumns}
                />
            }
            {
              !isFetching && discountedEquity !== null && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Discounted Equity'
                  value={`$${discountedEquityStr}`}
                  isTopRow
                  numColumns={collateralColumns.length}
                />
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

function BybitPositionsView (props: {
  data: readonly PositionItem[]
  isFetching: boolean
  editSubmission: (property: keyof PositionItem, value: any, editedPositionId: string) => void
  priceMap: Map<string, number>
  maintenanceMarginRequirement: number | null
}): React.JSX.Element {
  const {
    data,
    isFetching,
    editSubmission,
    priceMap,
    maintenanceMarginRequirement
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<PositionRow[]>([])
  const [order, setOrder] = useState<Order>('desc')
  const [orderBy, setOrderBy] = useState<keyof PositionRow>('marketValue')
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)

  const handleRequestSort = (property: keyof PositionRow): void => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  useEffect(() => {
    setRows(data.map((psn: PositionItem) => {
      const [quantDec, pxDec] = getDecimals(psn.priceInfo.price)
      const quotePrice = priceMap.get(psn.priceInfo.priceQuoteCurrencyId) ?? 1
      const frSign = psn.quantity < 0 ? 1 : -1
      return {
        id: psn.id,
        ticker: psn.ticker,
        quantity: psn.quantity,
        price: psn.priceInfo.price,
        unrealizedPnl: psn.settlementInfo?.unrealizedPnl ?? 0,
        settlementCurrencyId: psn.settlementInfo?.settlementCurrencyId ?? '',
        marginRequirementCoefficient: psn.marginRequirementCoefficient,
        priceDecimals: pxDec,
        quantityDecimals: quantDec,
        marketValue: psn.quantity * quotePrice * psn.priceInfo.price * psn.multiplier * (priceMap.get(psn.quoteCurrencyId) ?? 1),
        annualizedFundingRatePct: psn.annualizedFundingRate === undefined ? 0 : psn.annualizedFundingRate * 100,
        ourAnnualizedFundingRatePct: psn.annualizedFundingRate === undefined ? 0 : psn.annualizedFundingRate * 100 * frSign
      }
    }))
  }, [data])

  function handleSubmission (value: any, selectedIds: readonly string[], property?: keyof PositionItem): void {
    if (property !== undefined && selectedIds.length === 1) {
      editSubmission(property, value, selectedIds[0])
    }
  }

  const visibleRows = useMemo(
    () => {
      return stableSort(rows, (a, b) => {
        return getComparator(order, orderBy)({
          ...a,
          marketValue: Math.abs(a.marketValue)
        },
        {
          ...b,
          marketValue: Math.abs(b.marketValue)
        })
      })
    },
    [order, orderBy, rows]
  )
  const mmrStr = (maintenanceMarginRequirement ?? 0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box sx={{ height: '8%' }}>
        <Typography
          sx={{
            color: palette.tableBodyText.main,
            fontSize: '1.5rem'
          }}
        >
          Positions
        </Typography>
      </Box>
      <Box sx={{ width: '97%', height: '92%' }}>
        <TableContainer
          sx={{
            backgroundColor: palette.primary.main,
            borderRadius: '5px',
            height: '100%'
          }}
        >
          <Table
            sx={{
              tableLayout: 'fixed',
              height: '100%'
            }}
            stickyHeader
          >
            <SortableHeader
              columns={positionColumns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isFetching
                ? <TableSkeleton numRows={5} columns={positionColumns}/>
                : <AccountTableBody
                  rows={visibleRows}
                  handleOpenEdit={
                    (
                      itemId: string,
                      inputType: InputType,
                      label: string,
                      editProperty: keyof PositionItem
                    ) => {
                      setEditModalOpen(true)
                      setEditModalContent(
                        getModalContent(
                          inputType,
                          label,
                          editProperty,
                          () => { setEditModalOpen(false) },
                          handleSubmission,
                          [itemId],
                          () => {}
                        )
                      )
                    }
                  }
                  columns={positionColumns}
                />
            }
            {
              !isFetching && maintenanceMarginRequirement !== null && <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0
                }}
              >
                <AccountTableFooterRow
                  name='Maintenance Margin Requirement'
                  value={`$${mmrStr}`}
                  isTopRow
                  numColumns={positionColumns.length}
                />
              </TableFooter>
            }
          </Table>
        </TableContainer>
      </Box>
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
    </Stack>
  )
}

function BybitRiskContent (props: {
  hasEdited: boolean
  setHasEdited: (hasEdited: boolean) => void
  refreshSignal: boolean
  resetSignal: boolean
  shockModalOpen: boolean
  setShockModalOpen: (shockModalOpen: boolean) => void
}): React.JSX.Element {
  const { accountId } = useParams()
  const {
    hasEdited,
    setHasEdited,
    refreshSignal,
    resetSignal,
    shockModalOpen,
    setShockModalOpen
  } = props
  const [discountedEquity, setDiscountedEqutiy] = useState<number | null>(null)
  const [maintenanceMarginRequirement, setMaintenanceMarginRequirement] = useState<number | null>(null)
  const [editedCollateral, setEditedCollateral] = React.useState<readonly CollateralItem[]>([])
  const [editedPositions, setEditedPositions] = React.useState<readonly PositionItem[]>([])

  const { data: collateralData, refetch: collateralRefetch, isFetching: collateralIsFetching } = riskApi.useGetPlatformCollateralQuery({ accountId: accountId ?? '' })
  const { data: positionsData, refetch: positionsRefetch, isFetching: positionsIsFetching } = riskApi.useGetPlatformPositionsQuery({ accountId: accountId ?? '' })

  useEffect(() => {
    if ((collateralData ?? positionsData ?? undefined) === undefined) {
      setDiscountedEqutiy(null)
      setMaintenanceMarginRequirement(null)
    } else {
      const disEq = getBybitDiscountedEquity(editedCollateral, editedPositions)
      const mmr = getBybitMaintenanceMarginRequirement(editedCollateral, editedPositions)
      setDiscountedEqutiy(disEq)
      setMaintenanceMarginRequirement(mmr)
    }
  }, [editedCollateral, editedPositions, collateralData, positionsData])

  useEffect(() => {
    collateralRefetch().catch((err) => { console.error(err) })
    positionsRefetch().catch((err) => { console.error(err) })
  }, [refreshSignal])

  useEffect(() => {
    setEditedCollateral(collateralData?.data ?? [])
    setEditedPositions(positionsData?.data ?? [])
    setHasEdited(false)
  }, [resetSignal])

  useEffect(() => {
    setEditedCollateral(collateralData?.data ?? [])
    setEditedPositions(positionsData?.data ?? [])
    setHasEdited(false)
  }, [collateralData, positionsData])

  function handleBalanceEdit (property: keyof CollateralItem, value: any, editedCurrencyId: string): void {
    if (property === 'price') {
      const itemToUpdate = editedCollateral.find(item => item.id === editedCurrencyId)
      if (itemToUpdate === undefined) {
        return
      }
      const pxChangeFactor = value / itemToUpdate.price
      const updatedPositionsData = editedPositions.map(item => {
        if (item.baseCurrencyId === editedCurrencyId) {
          const newPrice = item.priceInfo.price * pxChangeFactor * item.delta
          if (item.settlementInfo === undefined) {
            return { ...item, priceInfo: { ...item.priceInfo, price: newPrice } }
          } else {
            const pxDelta = newPrice - item.priceInfo.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              priceInfo: {
                ...item.priceInfo,
                price: newPrice
              },
              settlementInfo: {
                settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
              }
            }
          }
        } else if (item.quoteCurrencyId === editedCurrencyId) {
          const newPrice = 1 / (1 / item.priceInfo.price * pxChangeFactor * item.delta)
          if (item.settlementInfo === undefined) {
            return { ...item, priceInfo: { ...item.priceInfo, price: newPrice } }
          } else {
            const pxDelta = newPrice - item.priceInfo.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              priceInfo: {
                ...item.priceInfo,
                price: newPrice
              },
              settlementInfo: {
                settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
              }
            }
          }
        } else {
          return item
        }
      })
      setEditedPositions(updatedPositionsData)
    } else if (property === 'balance') {
      const totalUsedAsRiskOffset = editedPositions.reduce((acc, item) => {
        if (item.riskOffsetInfo === undefined || item.riskOffsetInfo.riskOffsetCurrencyId !== editedCurrencyId) {
          return acc
        } else {
          return acc + item.riskOffsetInfo.riskOffsetAmount
        }
      }, 0)
      if (totalUsedAsRiskOffset > value) {
        const reductionFactor = value / totalUsedAsRiskOffset
        const updatedPositionsData = editedPositions.map(item => {
          if (item.riskOffsetInfo?.riskOffsetCurrencyId === editedCurrencyId) {
            return {
              ...item,
              riskOffsetInfo: {
                riskOffsetCurrencyId: item.riskOffsetInfo.riskOffsetCurrencyId,
                riskOffsetAmount: item.riskOffsetInfo.riskOffsetAmount * reductionFactor
              }
            }
          } else {
            return item
          }
        })
        setEditedPositions(updatedPositionsData)
      }
    }
    const updatedCollateralData = editedCollateral.map(item =>
      item.id === editedCurrencyId ? { ...item, [property]: value } : item
    )
    setEditedCollateral(updatedCollateralData)
    setHasEdited(true)
  }

  function handlePositionsEdit (property: keyof PositionItem, value: any, editedPositionId: string): void {
    if (property === 'priceInfo') {
      const itemToUpdate = editedPositions.find(item => item.id === editedPositionId)
      if (itemToUpdate === undefined) {
        return
      }
      const pxChangeFactor = (value / itemToUpdate.priceInfo.price) / itemToUpdate.delta
      const updatedCollateralData = editedCollateral.map(item => {
        if (item.id === itemToUpdate.baseCurrencyId) {
          return { ...item, price: item.price * pxChangeFactor }
        } else {
          return item
        }
      })
      setEditedCollateral(updatedCollateralData)
      const updatedData = editedPositions.map(item => {
        if (editedPositionId === item.id) {
          if (item.settlementInfo === undefined) {
            return { ...item, priceInfo: { ...item.priceInfo, price: value } }
          } else {
            const pxDelta = (value as number) - item.priceInfo.price
            const uplDelta = pxDelta * item.multiplier * item.quantity
            return {
              ...item,
              priceInfo: { ...item.priceInfo, price: value },
              settlementInfo: item.settlementInfo === undefined
                ? undefined
                : {
                    settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
                    unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
                  }
            }
          }
        } else {
          return item
        }
      })
      setEditedPositions(updatedData)
    } else {
      const updatedData = editedPositions.map(item =>
        editedPositionId === item.id ? { ...item, [property]: value } : item
      )
      setEditedPositions(updatedData)
    }
    setHasEdited(true)
  }

  function handlePriceShock (effects: ShockEffect[]): void {
    const shockMap = new Map(effects.map(effect => [effect.currencyId, effect.priceFactor]))
    const updatedCollateralData = editedCollateral.map(item => {
      const shockFactor = shockMap.get(item.id)
      if (shockFactor === undefined) {
        return item
      } else {
        return { ...item, price: item.price * shockFactor }
      }
    })
    setEditedCollateral(updatedCollateralData)
    const updatedPositionsData = editedPositions.map(item => {
      const baseShockFactor = shockMap.get(item.baseCurrencyId) ?? 1
      const quoteShockFactor = shockMap.get(item.quoteCurrencyId) ?? 1
      const netShockFactor = baseShockFactor / quoteShockFactor
      const newPrice = item.priceInfo.price * netShockFactor
      if (item.settlementInfo === undefined) {
        return { ...item, priceInfo: { ...item.priceInfo, price: newPrice } }
      } else {
        const pxDelta = newPrice - item.priceInfo.price
        const uplDelta = pxDelta * item.multiplier * item.quantity
        return {
          ...item,
          priceInfo: {
            ...item.priceInfo,
            price: newPrice
          },
          settlementInfo: {
            settlementCurrencyId: item.settlementInfo.settlementCurrencyId,
            unrealizedPnl: item.settlementInfo.unrealizedPnl + uplDelta
          }
        }
      }
    })
    setEditedPositions(updatedPositionsData)
    setHasEdited(true)
  }

  return (
    <Stack
      sx={{ height: '100%', width: '100%', alignItems: 'center' }}
      spacing={1}
    >
      <Box sx={{ width: '30%', height: '20vh', minWidth: '200px' }}>
        <HealthScoreDisplay
          accountId={accountId ?? ''}
          score={discountedEquity !== null && maintenanceMarginRequirement !== null ? discountedEquity / maintenanceMarginRequirement : null}
          isFetching={collateralIsFetching || positionsIsFetching}
          isEdited={hasEdited}
        />
      </Box>
      <Grid
        container
        overflow={'scroll'}
        sx={{ height: 'calc(78vh - 7% - 90px - 10px)' }}
      >
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <BybitCollateralView
            data={editedCollateral}
            isFetching={collateralIsFetching}
            editSubmission={handleBalanceEdit}
            discountedEquity={discountedEquity}
          />
        </Grid>
        <Grid item xs={12} lg={6} sx={{ height: '100%' }}>
          <BybitPositionsView
            data={editedPositions}
            isFetching={positionsIsFetching}
            editSubmission={handlePositionsEdit}
            priceMap={new Map(editedCollateral.map(item => [item.id, item.price]))}
            maintenanceMarginRequirement={maintenanceMarginRequirement}
          />
        </Grid>
      </Grid>
      <Modal
        handleClose={() => { setShockModalOpen(false) }}
        open={shockModalOpen}
      >
        <ShockModalContent
          processShock={handlePriceShock}
          closeModal={() => { setShockModalOpen(false) }}
        />
      </Modal>
    </Stack>
  )
}

export default BybitRiskContent
