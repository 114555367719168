import React, { useEffect, useState } from 'react'
import { Button, Box, Stack, IconButton, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Modal, RefreshButton, useRequestSnackbar } from '@r40cap/ui'
import { type MarginAlert, MarginAlertCreator, configApi, marginAlertsApi } from '@r40cap/alerts-sdk'

import MarginAlertsTable from '../../../alerts/MarginAlerts/MarginAlertsTable'
import type { AlertRow, InputType } from '../../../alerts/MarginAlerts/types'
import { isApiError } from '../../../../utils/errors'
import DeleteMarginAlertModalContent from '../../../alerts/MarginAlerts/DeleteMarginAlertModalContent'
import { getModalContent } from '../../../alerts/MarginAlerts/utils'

function AccountMarginAlertsModalContent (props: {
  accountId: string
  hasEdited: boolean
  isFetching: boolean
  editedAlerts: readonly MarginAlert[]
  refreshFunction: () => void
  pushEditsFunction: () => void
  handleEdit: (value: any, alertId: string, property: keyof MarginAlert) => void
}): React.JSX.Element {
  const {
    accountId,
    hasEdited,
    isFetching,
    refreshFunction,
    editedAlerts,
    pushEditsFunction,
    handleEdit
  } = props
  const { palette } = useTheme()
  const [rows, setRows] = useState<AlertRow[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<React.JSX.Element>(<></>)
  const { showSnackbar } = useRequestSnackbar()
  const { data: usersData } = configApi.useGetUsersQuery(undefined)
  const [postAdditions] = marginAlertsApi.useAddMarginAlertsMutation()

  useEffect(() => {
    const userIdToUsername = (usersData?.data ?? []).reduce((acc: Record<string, string>, user) => {
      acc[user.userId] = user.username
      return acc
    }, {})
    const newRows = editedAlerts.map((alert) => {
      return {
        id: alert.marginAlertId,
        account: 'Unused',
        alertType: alert.marginAlertType === 'live' ? 'Live' : 'Shocked',
        alertSide: alert.marginAlertSide === 'under' ? 'U' : 'O',
        alertSideSign: alert.marginAlertSide === 'under' ? -1 : 1,
        alertDeliveryMethod: alert.alertDeliveryMethod === 'slack_webhook'
          ? 'Slack'
          : alert.alertDeliveryMethod === 'fink_gpt'
            ? 'Fink GPT'
            : 'PagerDuty',
        deliveryIdentifier: alert.deliveryIdentifier,
        userId: alert.userId,
        user: userIdToUsername[alert.userId] ?? 'Unknown',
        currencyTicker: alert.currencyTicker,
        movement: alert.movement !== null && alert.movement !== undefined ? alert.movement * 100 : undefined,
        healthThreshold: alert.healthThreshold,
        cooldownMinutes: alert.cooldownMinutes
      }
    })
    setRows(newRows)
  }, [editedAlerts, usersData])

  function proposeDelete (marginAlertId: string): void {
    setModalContent(<DeleteMarginAlertModalContent
      marginAlertId={marginAlertId}
      closeModal={() => { setModalOpen(false) }}
    />)
    setModalOpen(true)
  }

  function addMarginAlert (alert: MarginAlert): void {
    showSnackbar({
      isOpen: true,
      message: 'Creating Alert',
      status: 'processing'
    })
    postAdditions({
      additions: [alert]
    })
      .then((value) => {
        if (isApiError(value.error)) {
          console.error(value.error.data)
          const msg = value.error.originalStatus === 400
            ? value.error.data
            : 'Unexpected Error, check logs'
          showSnackbar({
            isOpen: true,
            message: msg,
            status: 'error'
          })
        } else {
          showSnackbar({
            isOpen: true,
            message: 'Created Account',
            status: 'success'
          })
          closeModal()
        }
      })
      .catch((error) => {
        console.error(error)
        showSnackbar({
          isOpen: true,
          message: 'Failed to create Account',
          status: 'error'
        })
      })
  }

  function openAddModal (): void {
    setModalContent(<Box
      sx={{
        width: '40vw',
        padding: '20px'
      }}
    >
      <MarginAlertCreator
        submitFunction={addMarginAlert}
        mainColor={palette.accent.main}
        secondaryColor={palette.tableBodyText.main}
        spacing={2}
        fixedAccountId={accountId}
      />
    </Box>)
    setModalOpen(true)
  }

  function closeModal (): void {
    setModalContent(<></>)
    setModalOpen(false)
  }

  return <Stack
    spacing={1}
    direction={'column'}
    width={'100%'}
    sx={{ paddingBottom: '10px', height: 'calc(100% - 10px)' }}
  >
    <Box sx={{ height: '7%' }}>
      <Stack
        direction={'row'}
        spacing={1}
        alignContent={'center'}
        justifyContent={'flex-end'}
        sx={{
          height: '50%'
        }}
      >
        {
          hasEdited && <Button
            variant='outlined'
            onClick={pushEditsFunction}
            sx={{
              outlineColor: palette.accent.main,
              color: palette.accent.main
            }}
          >
            PUSH
          </Button>
        }
        <IconButton
          onClick={openAddModal}
        >
          <AddIcon />
        </IconButton>
        <RefreshButton
          refreshFunction={refreshFunction}
          iconColor={palette.primary.main}
          buttonColor={palette.accent.main}
        />
      </Stack>
    </Box>
    <Box sx={{ height: '93%' }}>
      <MarginAlertsTable
        rows={rows}
        isFetching={isFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof MarginAlert
          ) => {
            setModalOpen(true)
            setModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={proposeDelete}
        showAccount={false}
      />
    </Box>
    <Modal
      open={modalOpen}
      handleClose={closeModal}
    >
      {modalContent}
    </Modal>
  </Stack>
}

export default AccountMarginAlertsModalContent
