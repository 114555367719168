import React, { useEffect, useMemo, useState } from 'react'
import type { Dayjs } from 'dayjs'
import {
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import {
  type Order,
  RowSkeleton,
  getComparator,
  stableSort
} from '@r40cap/ui'
import type { BalanceByAccount, Price } from '@r40cap/pms-sdk'

import { ShallowRow } from './rows'
import type {
  BalanceWithPrice,
  ColumnDefinitionWithStaleness,
  FirstLevelDef,
  PortfolioRow
} from './types'
import { getAbsoluteValueComparator } from './utils'

function getUniqueAccounts (positions: BalanceByAccount[]): FirstLevelDef[] {
  const filteredObjects = positions.reduce((accumulator: Record<string, BalanceByAccount>, currentObject: BalanceByAccount) => {
    accumulator[currentObject.accountId] = currentObject
    return accumulator
  }, {})
  const uniqueObjects: BalanceByAccount[] = Object.values(filteredObjects)
  return uniqueObjects.map((psn) => {
    return {
      id: psn.accountId,
      name: `${psn.platformName} - ${psn.accountName}`
    }
  })
}

function PositionsByAccountTableBody (props: {
  positions: BalanceWithPrice[]
  referencePrice?: Price
  isLoading: boolean
  order: Order
  orderBy: keyof PortfolioRow
  columns: Array<ColumnDefinitionWithStaleness<PortfolioRow, any>>
  includeSmallAmounts: boolean
  smallThreshold: number
  requestedTime?: Dayjs
  openedAccounts: readonly string[]
  toggleAccount: (accountId: string) => void
}): React.JSX.Element {
  const {
    positions,
    isLoading,
    order,
    orderBy,
    columns,
    referencePrice,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    openedAccounts,
    toggleAccount
  } = props
  const [accounts, setAccounts] = useState<FirstLevelDef[]>(getUniqueAccounts(positions))

  const visibleAccounts = useMemo(
    () => {
      const filteredAccounts = includeSmallAmounts
        ? accounts
        : accounts.filter((acc) => {
          const accRows = positions.filter((val) => val.accountId === acc.id)
          const absMv = accRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredAccounts, (a, b) => {
        const stratARows = positions.filter((val) => val.accountId === a.id)
        const psnA = positions.find((val) => val.accountId === a.id)
        const psnRowA: PortfolioRow = {
          name: psnA !== undefined ? `${psnA.platformName} - ${psnA.accountName}` : '',
          marketValue: stratARows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        const stratBRows = positions.filter((val) => val.accountId === b.id)
        const psnB = positions.find((val) => val.accountId === b.id)
        const psnRowB: PortfolioRow = {
          name: psnB !== undefined ? `${psnB.platformName} - ${psnB.accountName}` : '',
          marketValue: stratBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : stratBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        return orderBy === 'marketValue' || orderBy === 'referenceMarketValue'
          ? getAbsoluteValueComparator(order, orderBy)(psnRowA, psnRowB)
          : getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, positions, accounts, includeSmallAmounts, smallThreshold]
  )

  useEffect(() => {
    setAccounts(getUniqueAccounts(positions))
  }, [positions])

  if (isLoading || positions === undefined) {
    const rows = []
    for (let i = 0; i < 5; i++) {
      rows.push(
        <RowSkeleton
          usedKey={i}
          columns={columns}
          frontBuffer={{
            key: 'toggle',
            alignment: 'center',
            variant: 'rectangular'
          }}
          key={i}
        />
      )
    }
    return (
      <TableBody>
        {rows}
        <TableRow sx={{ height: '100%' }} />
      </TableBody>
    )
  } else {
    const rows = visibleAccounts.map((account) => {
      return (
        <ShallowRow
          rowName={account.name}
          bottomRows={positions.filter((psn) => psn.accountId === account.id)}
          key={account.id}
          order={order}
          orderBy={orderBy}
          columns={columns}
          referencePrice={referencePrice}
          includeSmallAmounts={includeSmallAmounts}
          smallThreshold={smallThreshold}
          requestedTime={requestedTime}
          isOpen={openedAccounts.includes(account.id)}
          toggleOpen={() => { toggleAccount(account.id) }}
        />
      )
    })
    if (rows.length > 0) {
      return (
        <TableBody>
          {rows}
          <TableRow sx={{ height: '100%' }} />
        </TableBody>
      )
    } else {
      return (
        <TableBody>
          <TableRow sx={{ height: '100%' }}>
            <TableCell
              colSpan={columns.length + 1}
              align='center'
            >
              <Typography sx={{ fontSize: 20 }}>No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      )
    }
  }
}

export default PositionsByAccountTableBody
