import React, { useEffect, useState } from 'react'
import { Button, Box, Stack, Typography, useTheme } from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { type MarginAlert, marginAlertsApi } from '@r40cap/alerts-sdk'
import { Modal, useRequestSnackbar } from '@r40cap/ui'

import AccountMarginAlertsModalContent from './AccountMarginAlertsModalContent'
import { isApiError } from '../../../../utils/errors'

function AccountMarginAlertsDisplay (props: { accountId: string }): React.JSX.Element {
  const { accountId } = props
  const { palette } = useTheme()
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [editedAlerts, setEditedAlerts] = useState<readonly MarginAlert[]>([])
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const { showSnackbar } = useRequestSnackbar()

  const {
    data: alertsData,
    refetch: alertsRefetch,
    isFetching: alertsIsFetching
  } = marginAlertsApi.useGetMarginAlertsQuery({ accountId: accountId ?? '' })
  const [postEditsMutation] = marginAlertsApi.useEditMarginAlertsMutation()

  useEffect(() => {
    if (alertsData?.data !== undefined && alertsData.data !== null) {
      setEditedAlerts(alertsData.data)
    }
  }, [alertsData])

  function refreshFunction (): void {
    void alertsRefetch()
      .catch((error) => { console.error(error) })
      .then(() => { setEditedAlerts(alertsData?.data ?? []) })
  }

  const handleEdit = (value: any, alertId: string, property: keyof MarginAlert): void => {
    const usedValue = property === 'movement' ? value / 100 : value
    const updatedData = editedAlerts.map(item =>
      item.marginAlertId === alertId ? { ...item, [property]: usedValue } : item
    )
    setEditedAlerts(updatedData)
    const editedSet = new Set(editedList)
    editedSet.add(alertId)
    setEditedList(Array.from(editedSet))
    setHasEdited(true)
  }

  function pushEdits (): void {
    const editedEditObjects = editedAlerts.filter(data => editedList.includes(data.marginAlertId))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Instrument Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
            setEditedList([])
            setHasEdited(false)
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }

  return <>
    <Button
      sx={{
        width: '100%',
        height: '100%',
        borderWidth: '2px',
        borderColor: palette.tableBodyText.main,
        borderStyle: 'solid',
        borderRadius: '15px'
      }}
      onClick={() => { setModalOpen(true) }}
    >
      <Stack direction={'row'} sx={{ alignItems: 'center' }}>
        <NotificationsIcon
          sx={{
            fontSize: '1rem',
            color: palette.tableBodyText.main,
            alignContent: 'center'
          }}
        />
        <Typography sx={{
          color: palette.tableBodyText.main,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          marginLeft: '5px'
        }}>
          {editedAlerts.length} Margin {editedAlerts.length === 1 ? 'Alert' : 'Alerts'}
        </Typography>
      </Stack>
    </Button>
    <Modal
      open={modalOpen}
      handleClose={() => {
        console.log('Closing Modal')
        setModalOpen(false)
        console.log(modalOpen)
      }}
    >
      <Box
        sx={{
          width: '60vw',
          height: '80vh',
          padding: '20px'
        }}
      >
        <AccountMarginAlertsModalContent
          hasEdited={hasEdited}
          isFetching={alertsIsFetching}
          editedAlerts={editedAlerts}
          refreshFunction={refreshFunction}
          pushEditsFunction={pushEdits}
          accountId={accountId ?? ''}
          handleEdit={handleEdit}
        />
      </Box>
    </Modal>
  </>
}

export default AccountMarginAlertsDisplay
