export enum InputType {
  PRICE_INFO,
  FLOAT,
  PERCENTAGE
}

export interface ShockEffect {
  currencyId: string
  priceFactor: number
}
