import React, { useEffect, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Stack } from '@mui/material'
import { useAuth } from '@r40cap/auth'

import NoAccess from './NoAccess'
import Page from './Page'
import PnlControlsPanel from '../components/pnl/PnlControlsPanel'
import PnlTable from '../components/pnl/PnlTable'
import { deskOptions } from '../components/common/constants'
import type { DeskOption } from '../components/common/types'
import { type PnlTimeOption, PnlTimeType } from '../components/pnl/types'
import { pnlTimingOptions } from '../components/pnl/constants'
import { getLastMark } from '../utils/times'

function Pnl (): React.JSX.Element {
  dayjs.extend(utc)
  const authContext = useAuth()
  const [time, setTime] = useState<Dayjs | null>(null)
  const [refreshSignal, setRefreshSignal] = useState(false)
  const [includeUnsettled, setIncludeUnsettled] = useState(true)
  const [conciseBasis, setConciseBasis] = useState(authContext.restrictedDeskId === '209b1408-beab-4d3a-b7ef-476040d7d6ff')
  const [pnlTiming, setPnlTiming] = useState<PnlTimeOption>(pnlTimingOptions[0])
  const [desk, setDesk] = useState<DeskOption>(deskOptions[0])

  useEffect(() => {
    setTime(null)
  }, [pnlTiming])

  function deskChangeFunction (newDeskOption: DeskOption): void {
    if (newDeskOption.id === '209b1408-beab-4d3a-b7ef-476040d7d6ff') {
      setConciseBasis(true)
    } else {
      setConciseBasis(false)
    }
    setDesk(newDeskOption)
  }

  return (
    authContext.hasPnlAccess as boolean
      ? <Page>
          <Stack
            spacing={1}
            direction={'column'}
            width={'100%'}
            sx={{ paddingTop: '90px', paddingBottom: '10px', height: 'calc(100% - 90px - 10px)' }}
          >
            <Box sx={{ height: '7%' }}>
              <PnlControlsPanel
                time={time}
                timeChangeFunction={setTime}
                pnlTiming={pnlTiming}
                pnlTimingChangeFunction={setPnlTiming}
                includeUnsettled={includeUnsettled}
                includeUnsettledChangeFunction={setIncludeUnsettled}
                refreshFunction={() => { setRefreshSignal(!refreshSignal) }}
                conciseBasis={conciseBasis}
                setConciseBasis={setConciseBasis}
                desk={desk}
                setDesk={deskChangeFunction}
              />
            </Box>
            <Box sx={{ height: '93%' }}>
              <PnlTable
                time={
                  pnlTiming.type === PnlTimeType.CURRENT
                    ? undefined
                    : pnlTiming.type === PnlTimeType.LAST_MARK
                      ? getLastMark()
                      : (time ?? undefined)
                }
                includeUnsettled={includeUnsettled}
                refreshSignal={refreshSignal}
                desk={desk}
                conciseBasis={conciseBasis}
              />
            </Box>
          </Stack>
        </Page>
      : <NoAccess />
  )
}

export default Pnl
