import { type ColumnDefinition } from '@r40cap/ui'

import type { CollateralRow, DummyCollateralObject, PositionRow, DummyPositionObject } from './types'
import { InputType } from '../types'

export const collateralColumns: Array<ColumnDefinition<CollateralRow, InputType, DummyCollateralObject>> = [
  {
    label: 'Asset',
    id: 'ticker',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Balance',
    id: 'balance',
    columnWidthPercentage: 22,
    overflowType: 'scroll',
    editId: 'balance',
    inputType: InputType.FLOAT,
    sortable: true,
    colored: false,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    editId: 'price',
    inputType: InputType.FLOAT,
    sortable: true,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 22,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]

export const positionColumns: Array<ColumnDefinition<PositionRow, InputType, DummyPositionObject>> = [
  {
    label: 'Position',
    id: 'ticker',
    columnWidthPercentage: 18,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Quantity',
    id: 'quantity',
    columnWidthPercentage: 14,
    overflowType: 'scroll',
    editId: 'quantity',
    inputType: InputType.FLOAT,
    sortable: true,
    colored: true,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Delta',
    id: 'delta',
    columnWidthPercentage: 6,
    overflowType: 'scroll',
    sortable: false,
    colored: true,
    decimalsId: 'quantityDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    decimals: 2
  },
  {
    label: 'Price',
    id: 'price',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    editId: 'price',
    inputType: InputType.FLOAT,
    sortable: false,
    colored: false,
    decimalsId: 'priceDecimals',
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'Curr.',
    id: 'priceCurrency',
    columnWidthPercentage: 10,
    overflowType: 'scroll',
    sortable: false,
    colored: false,
    valueDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    },
    headDetails: {
      alignment: 'left',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'IV',
    id: 'impliedVolatilityPct',
    columnWidthPercentage: 8,
    overflowType: 'scroll',
    editId: 'iv',
    inputType: InputType.PERCENTAGE,
    sortable: false,
    colored: false,
    decimals: 1,
    valueDetails: {
      alignment: 'right',
      prefix: null,
      suffix: '%'
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  },
  {
    label: 'MV',
    id: 'marketValue',
    columnWidthPercentage: 15,
    overflowType: 'scroll',
    sortable: true,
    colored: false,
    decimals: 0,
    valueDetails: {
      alignment: 'right',
      prefix: '$',
      suffix: null
    },
    headDetails: {
      alignment: 'right',
      prefix: null,
      suffix: null
    }
  }
]
