import React from 'react'

import { UpdatingInputType } from './types'
import { NumberEditContent, StringEditContent } from '../modals'

export function getEditModalContent (
  inputType: UpdatingInputType,
  label: string,
  closeModal: () => void,
  handleSubmission: (value: any) => void,
  currentValue: any
): React.JSX.Element {
  let content: React.JSX.Element = <></>
  switch (inputType) {
    case UpdatingInputType.TEXT:
      content = <StringEditContent
          title={label}
          closeModal={closeModal}
          submit={handleSubmission}
          prefillValue={currentValue}
        />
      break
    default:
      content = <NumberEditContent
          closeModal={closeModal}
          submit={handleSubmission}
          title={label}
          prefillValue={currentValue}
        />
  }
  return content
}

function isRelevantSymbol (symbol: string): boolean {
  if (symbol.endsWith('-FundingAccount')) {
    return false
  } else if (symbol.endsWith('-CustodyFundingAccount')) {
    return false
  } else if (symbol.endsWith('-FundingAccount:spot')) {
    return false
  } else if (symbol === 'CITY:spot') {
    return false
  } else if (symbol === 'SPEC') {
    return false
  } else {
    return true
  }
}

export function filterRelevantUnmatchedSymbols (symbols: readonly string[]): readonly string[] {
  return symbols.filter(isRelevantSymbol)
}
