import React, { useMemo, useState, useEffect } from 'react'
import {
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { type Order, ValueCell, stableSort, getComparator } from '@r40cap/ui'
import { type SuperStrategyPnl } from '@r40cap/pms-sdk'

import { pnlColumns } from '../constants'
import SubStrategyRow from './SubStrategyRow'
import { type PnlRow } from '../types'

function SuperStrategyRow (props: {
  superStrategyPnl: SuperStrategyPnl
  order: Order
  orderBy: keyof PnlRow
  superStrategiesForcedOpen: boolean
  strategiesForcedOpen: boolean
  forcedClosed: boolean
  invalidateForces: () => void
  conciseBasis: boolean
}): React.JSX.Element {
  const {
    superStrategyPnl,
    order,
    orderBy,
    superStrategiesForcedOpen,
    strategiesForcedOpen,
    forcedClosed,
    invalidateForces,
    conciseBasis
  } = props
  const [open, setOpen] = useState<boolean>(false)
  const { palette } = useTheme()

  const visibleSubStrategies = useMemo(
    () => stableSort(superStrategyPnl.subStrategies, (a, b) => {
      const pnlRowA: PnlRow = {
        title: a.name,
        day: a.pnlSnapshot.day,
        week: a.pnlSnapshot.week,
        month: a.pnlSnapshot.month,
        year: a.pnlSnapshot.year,
        inception: a.pnlSnapshot.inception
      }
      const pnlRowB: PnlRow = {
        title: b.name,
        day: b.pnlSnapshot.day,
        week: b.pnlSnapshot.week,
        month: b.pnlSnapshot.month,
        year: b.pnlSnapshot.year,
        inception: b.pnlSnapshot.inception
      }
      return getComparator(order, orderBy)(pnlRowA, pnlRowB)
    }),
    [order, orderBy, superStrategyPnl]
  )

  useEffect(() => {
    if (superStrategiesForcedOpen) {
      setOpen(true)
    }
  }, [superStrategiesForcedOpen])

  useEffect(() => {
    if (forcedClosed) {
      setOpen(false)
    }
  }, [forcedClosed])

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!(open as boolean))
              invalidateForces()
            }}
          >
            {
              (superStrategiesForcedOpen || (open as boolean && !forcedClosed))
                ? <KeyboardArrowUpIcon fontSize='small'/>
                : <KeyboardArrowDownIcon fontSize='small'/>
            }
          </IconButton>
        </TableCell>
        {
          pnlColumns.map((column, idx) => (
            <ValueCell<PnlRow, any>
              column={column}
              item={{
                title: superStrategyPnl.name,
                day: superStrategyPnl.pnlSnapshot.day,
                week: superStrategyPnl.pnlSnapshot.week,
                month: superStrategyPnl.pnlSnapshot.month,
                year: superStrategyPnl.pnlSnapshot.year,
                inception: superStrategyPnl.pnlSnapshot.inception
              }}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              key={idx}
            />
          ))
        }
      </TableRow>
      {(superStrategiesForcedOpen || (open && !forcedClosed)) && visibleSubStrategies.map((subStrategy, idx) => <SubStrategyRow
        subStrategyPnl={subStrategy}
        order={order}
        orderBy={orderBy}
        key={idx}
        forcedOpen={strategiesForcedOpen}
        forcedClosed={forcedClosed}
        invalidateForces={invalidateForces}
        conciseBasis={conciseBasis}
      />)}
    </>
  )
}

export default SuperStrategyRow
