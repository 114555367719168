import React, { useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Button, Box, Stack, useTheme } from '@mui/material'
import { Modal, RefreshButton } from '@r40cap/ui'

import OkxRiskContent from './okx/OkxRiskContent'
import DeribitXPMRiskContent from './deribit/DeribitXPMRiskContent'
import InvalidEngineContent from './InvalidEngineContent'
import RiskComponentsBanner from './RiskComponentsBanner'
import BybitRiskContent from './bybit/BybitRiskContent'

function RiskModal (): React.JSX.Element {
  const navigate = useNavigate()
  const { palette } = useTheme()
  const [refreshSignal, setRefreshSignal] = useState<boolean>(false)
  const [resetSignal, setResetSignal] = useState<boolean>(false)
  const [shockModalOpen, setShockModalOpen] = useState<boolean>(false)
  const [hasEdited, setHasEdited] = useState<boolean>(false)

  function exitModal (): void {
    if ((Boolean(window.history.state)) && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate('/portfolio/balances')
    }
  }

  function refreshFunction (): void {
    setHasEdited(false)
    setRefreshSignal(!refreshSignal)
  }

  function resetFunction (): void {
    setHasEdited(false)
    setResetSignal(!resetSignal)
  }

  return (
    <Modal
      open
      handleClose={exitModal}
    >
      <Stack
        direction='column'
        sx={{
          width: '90vw',
          height: '90vh',
          padding: '20px'
        }}
      >
        <Routes>
          <Route path=":engineName/:accountId" element={<RiskComponentsBanner />} />
        </Routes>
        <Box
          sx={{
            height: '7%',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Stack
            direction={'row'}
            spacing={1}
            alignContent={'center'}
            sx={{ height: '50%' }}
          >
            {
              hasEdited && <Button
                variant='outlined'
                onClick={resetFunction}
                sx={{
                  outlineColor: palette.accent.main,
                  color: palette.accent.main
                }}
              >
                RESET
              </Button>
            }
            <Button
              variant='outlined'
              onClick={() => { setShockModalOpen(true) }}
              sx={{
                outlineColor: palette.accent.main,
                color: palette.accent.main
              }}
            >
              SHOCK
            </Button>
            <RefreshButton
              refreshFunction={refreshFunction}
              iconColor={palette.primary.main}
              buttonColor={palette.accent.main}
            />
          </Stack>
        </Box>
        <Routes>
          <Route path='' element={<InvalidEngineContent />} />
          <Route
            path='okx/:accountId'
            element={<OkxRiskContent
              resetSignal={resetSignal}
              refreshSignal={refreshSignal}
              setHasEdited={setHasEdited}
              hasEdited={hasEdited}
              shockModalOpen={shockModalOpen}
              setShockModalOpen={setShockModalOpen}
            />}
          />
          <Route
            path='deribit-xpm/:accountId'
            element={<DeribitXPMRiskContent
              resetSignal={resetSignal}
              refreshSignal={refreshSignal}
              setHasEdited={setHasEdited}
              hasEdited={hasEdited}
              shockModalOpen={shockModalOpen}
              setShockModalOpen={setShockModalOpen}
            />}
          />
          <Route
            path='bybit/:accountId'
            element={<BybitRiskContent
              resetSignal={resetSignal}
              refreshSignal={refreshSignal}
              setHasEdited={setHasEdited}
              hasEdited={hasEdited}
              shockModalOpen={shockModalOpen}
              setShockModalOpen={setShockModalOpen}
            />}
          />
          <Route path='*' element={<InvalidEngineContent />} />
        </Routes>
      </Stack>
    </Modal>
  )
}

export default RiskModal
