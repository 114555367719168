import React, { useEffect, useMemo, useState } from 'react'
import dayjs, { type Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import utc from 'dayjs/plugin/utc'
import {
  IconButton,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { type Order, getComparator, stableSort } from '@r40cap/ui'
import type { Price } from '@r40cap/pms-sdk'

import { ValueCellWithColor } from './cells'
import BaseFxRowWithPriority from './BaseFxRowWithPriority'
import type { PositionRow, PositionObject, ColumnDefinitionWithColor, PositionWithPrice } from '../types'

function getUniqueBases (positions: PositionObject[]): string[] {
  const allBases = new Set(positions.map((row) => row.baseFxId))
  return Array.from(allBases)
}

function FirstLevelRowWithPriority (props: {
  rowName: string
  priority: number
  referencePrice?: Price
  strategyColor: string
  bottomRows: PositionWithPrice[]
  includeSmallAmounts: boolean
  smallThreshold: number
  order: Order
  orderBy: keyof PositionRow
  columns: Array<ColumnDefinitionWithColor<PositionRow, any>>
  requestedTime?: Dayjs
  isOpen: boolean
  toggleOpen: () => void
  openChildren: readonly string[]
  openChild: (childId: string) => void
}): React.JSX.Element {
  dayjs.extend(minMax)
  dayjs.extend(utc)
  const {
    priority,
    rowName,
    bottomRows,
    order,
    orderBy,
    columns,
    strategyColor,
    referencePrice,
    includeSmallAmounts,
    smallThreshold,
    requestedTime,
    isOpen,
    toggleOpen,
    openChildren,
    openChild
  } = props
  const [baseFxs, setBaseFxs] = useState<string []>(getUniqueBases(bottomRows))
  const { palette } = useTheme()

  useEffect(() => {
    const uniqueBases = getUniqueBases(bottomRows)
    setBaseFxs(uniqueBases)
  }, [bottomRows])

  const strategyRowObject: PositionRow = {
    name: rowName,
    priorityLevel: priority,
    strategyColor,
    marketValue: bottomRows.filter(row => row.price !== undefined).length > 0
      ? bottomRows.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) ?? 0
      : undefined,
    referenceMarketValue: (referencePrice !== undefined) && bottomRows.filter(row => row.price !== undefined).length > 0
      ? bottomRows.reduce((partialSum, a) => partialSum + a.multiplier * (a.price ?? 0) * a.quantity, 0) / referencePrice.price
      : undefined,
    priceDecimals: 0,
    quantityDecimals: 0,
    requestedTime,
    receivedTime: dayjs.min(bottomRows.map((row) => dayjs.utc(row.priceTime))) ?? dayjs().utc()
  }

  const visibleBases = useMemo(
    () => {
      const filteredBaseFxs = includeSmallAmounts
        ? baseFxs
        : baseFxs.filter((baseFx) => {
          const baseFxRows = bottomRows.filter((val) => val.baseFxId === baseFx)
          const absMv = baseFxRows.reduce((sum, current) => sum + Math.abs(current.multiplier * (current.price ?? 0) * current.quantity), 0)
          return absMv >= smallThreshold
        })
      return stableSort(filteredBaseFxs, (a, b) => {
        const baseARows = bottomRows.filter((val) => val.baseFxId === a)
        const psnRowA: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: baseARows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? baseARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : baseARows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          deltaQuantity: baseARows.reduce((sum, current) => sum + current.multiplier * (current.delta ?? 1) * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        const baseBRows = bottomRows.filter((val) => val.baseFxId === b)
        const psnRowB: PositionRow = {
          name: bottomRows.find((val) => val.baseFxId === a)?.baseFxName ?? '',
          priorityLevel: priority,
          strategyColor,
          marketValue: baseBRows.reduce((sum, current) => sum + current.multiplier * (current.price ?? 0) * current.quantity, 0),
          referenceMarketValue: referencePrice !== undefined
            ? baseBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0) / referencePrice.price
            : baseBRows.reduce((sum, current) => sum + (current.price ?? 0) * current.multiplier * current.quantity, 0),
          deltaQuantity: baseBRows.reduce((sum, current) => sum + current.multiplier * (current.delta ?? 1) * current.quantity, 0),
          priceDecimals: 0,
          quantityDecimals: 0
        }
        return getComparator(order, orderBy)({ ...psnRowA, requestedTime: undefined, receivedTime: undefined }, { ...psnRowB, requestedTime: undefined, receivedTime: undefined })
      })
    },
    [order, orderBy, bottomRows, includeSmallAmounts, smallThreshold]
  )

  return (
    <>
      <TableRow>
        <TableCell sx={{ padding: 1 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={toggleOpen}
          >
            {
              isOpen
                ? <KeyboardArrowUpIcon fontSize='small'/>
                : <KeyboardArrowDownIcon fontSize='small'/>
            }
          </IconButton>
        </TableCell>
        {
          columns.map((column, idx) => (
            <ValueCellWithColor<PositionRow, any>
              column={column}
              item={strategyRowObject}
              key={idx}
              defaultTextColor={palette.tableBodyText.main}
              redTextColor='red'
              greenTextColor='green'
              dense
            />
          ))
        }
      </TableRow>
      {isOpen && visibleBases.map((base) => {
        return (
          <BaseFxRowWithPriority
            key={base}
            priority={priority}
            bottomRows={
              bottomRows.filter((row) => row.baseFxId === base).map((row) => {
                return {
                  ...row,
                  baseFxName: `\u00A0\u00A0\u00A0${row.baseFxName}`
                }
              })
            }
            order={order}
            orderBy={orderBy}
            columns={columns}
            strategyColor={strategyColor}
            referencePrice={referencePrice}
            includeSmallAmounts={includeSmallAmounts}
            smallThreshold={smallThreshold}
            isOpen={openChildren.includes(base)}
            toggleOpen={() => { openChild(base) }}
            requestedTime={requestedTime}
          />
        )
      })}
    </>
  )
}

export default FirstLevelRowWithPriority
