import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { Modal, useRequestSnackbar } from '@r40cap/ui'
import { glossaryApi, type LiteAccount } from '@r40cap/pms-sdk'
import { type MarginAlert, type Permissions, configApi, marginAlertsApi, usePermissions } from '@r40cap/alerts-sdk'

import type { AlertRow, InputType } from './types'
import MarginAlertsTable from './MarginAlertsTable'
import { isApiError } from '../../../utils/errors'
import { getModalContent } from './utils'

function MarginAlerts (props: {
  refreshSignal: boolean
  pushSignal: boolean
  setHasEdited: (hasEdited: boolean) => void
}): React.JSX.Element {
  const {
    refreshSignal,
    pushSignal,
    setHasEdited
  } = props
  const [editedList, setEditedList] = useState<readonly string[]>([])
  const [editedData, setEditedData] = useState<MarginAlert[]>([])
  const [rows, setRows] = useState<AlertRow[]>([])
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [editModalContent, setEditModalContent] = useState<React.JSX.Element>(<></>)
  const { hasMarginAlertsAccess } = usePermissions()
  const { showSnackbar } = useRequestSnackbar()
  const navigate = useNavigate()

  const {
    data: alertsData,
    isFetching: alertsIsFetching,
    refetch: alertsRefetch
  } = marginAlertsApi.useGetMarginAlertsQuery({})
  const [postEditsMutation] = marginAlertsApi.useEditMarginAlertsMutation()

  const { data: usersData } = configApi.useGetUsersQuery(undefined)
  const { data: accountsData } = glossaryApi.useGetAccountsLiteQuery({ getAll: false })

  useEffect(() => {
    alertsRefetch()
      .catch((error) => { console.error(error) })
  }, [refreshSignal])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEdit = (value: any, marginAlertId: string, property: keyof MarginAlert): void => {
    const usedValue = property === 'movement' ? value / 100 : value
    const updatedData = editedData.map(item =>
      item.marginAlertId === marginAlertId ? { ...item, [property]: usedValue } : item
    )
    setEditedData(updatedData)
    const editedSet = new Set(editedList)
    editedSet.add(marginAlertId)
    setEditedList(Array.from(editedSet))
    setHasEdited(true)
  }

  useEffect(() => {
    const editedEditObjects = editedData.filter(data => editedList.includes(data.marginAlertId))
    if (editedEditObjects.length > 0) {
      showSnackbar({
        isOpen: true,
        message: 'Pushing Instrument Edits',
        status: 'processing'
      })
      postEditsMutation({ edits: editedEditObjects })
        .then((value) => {
          if (isApiError(value.error)) {
            console.error(value.error.data)
            const msg = value.error.originalStatus === 400
              ? value.error.data
              : 'Unexpected Error, check logs'
            showSnackbar({
              isOpen: true,
              message: msg,
              status: 'error'
            })
          } else {
            showSnackbar({
              isOpen: true,
              message: 'Edits Pushed',
              status: 'success'
            })
            setHasEdited(false)
            setEditedList([])
          }
        })
        .catch((error) => {
          console.error(error)
          showSnackbar({
            isOpen: true,
            message: 'Failed to push edits',
            status: 'error'
          })
        })
    }
  }, [pushSignal])

  useEffect(() => {
    if (alertsData !== null && alertsData !== undefined) {
      setEditedData(alertsData.data)
    }
  }, [alertsData])

  useEffect(() => {
    const userIdToUsername = (usersData?.data ?? []).reduce((acc: Record<string, string>, user: Permissions) => {
      acc[user.userId] = user.username
      return acc
    }, {})
    const accountIdToDisplay = (accountsData?.data ?? []).reduce((acc: Record<string, string>, account: LiteAccount) => {
      acc[account.id] = `${account.platform.name} - ${account.name}`
      return acc
    }, {})
    const newRows = editedData.map((alert) => {
      return {
        id: alert.marginAlertId,
        account: accountIdToDisplay[alert.accountId] ?? alert.accountId,
        alertType: alert.marginAlertType === 'live' ? 'Live' : 'Shocked',
        alertSide: alert.marginAlertSide === 'under' ? 'U' : 'O',
        alertSideSign: alert.marginAlertSide === 'under' ? -1 : 1,
        alertDeliveryMethod: alert.alertDeliveryMethod === 'slack_webhook'
          ? 'Slack'
          : alert.alertDeliveryMethod === 'fink_gpt'
            ? 'Fink GPT'
            : 'PagerDuty',
        deliveryIdentifier: alert.deliveryIdentifier,
        userId: alert.userId,
        user: userIdToUsername[alert.userId] ?? 'Unknown',
        currencyTicker: alert.currencyTicker,
        movement: alert.movement !== null && alert.movement !== undefined ? alert.movement * 100 : undefined,
        healthThreshold: alert.healthThreshold,
        cooldownMinutes: alert.cooldownMinutes
      }
    })
    setRows(newRows)
  }, [editedData, usersData, accountsData])

  if (!(hasMarginAlertsAccess ?? false)) {
    return <Box sx={{
      height: '100%',
      width: '100%',
      alignContent: 'center',
      justifyItems: 'center'
    }}>
      <Typography align='center' variant='h4'>Insufficient Permissions</Typography>
    </Box>
  }

  return (
    <Box sx={{ height: '100%' }}>
      <MarginAlertsTable
        rows={rows}
        isFetching={alertsIsFetching}
        handleOpenEdit={
          (
            itemId: string,
            inputType: InputType,
            label: string,
            editProperty: keyof MarginAlert
          ) => {
            setEditModalOpen(true)
            setEditModalContent(
              getModalContent(
                inputType,
                label,
                editProperty,
                handleEdit,
                itemId,
                () => { setEditModalOpen(false) }
              )
            )
          }
        }
        proposeDelete={(marginAlertId: string) => { navigate(`/alerts/margin/delete/${marginAlertId}`) }}
        showAccount
      />
      <Modal
        open={editModalOpen}
        handleClose={() => { setEditModalOpen(false) }}
      >
        {editModalContent}
      </Modal>
      <Outlet />
    </Box>
  )
}

export default MarginAlerts
